.static {
	display: block;
}

p {
	a {
		color: var(--primary);

		&:hover {
			opacity: 0.7;
		}
	}
}

.inner-content-wrap {
	ul {
		padding: 0 0 0 40px;
		margin-bottom: 20px;

		li {
			font-family: var(--d-font3);
			color: #666;
			line-height: 1.5;
			margin-bottom: 7px;
			font-size: 16px;

		}
	}

}

.unit-list-section {
	.min-row {
		display: flex;
		flex-wrap: wrap;
	}
}

.inner-banner {
	background-image: url(RESOURCE/img/inner-banner-new.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 390px;
	position: relative;

	@media (max-width:992px) {
		height: 320px;
	}


	.page-title {
		position: absolute;
		bottom: 70px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		h1 {
			font-size: 42px;
			margin-bottom: 0;
			color: var(--white);
			text-transform: uppercase;

			@media (max-width:992px) {
				font-size: 30px;
			}
		}

	}
}

.heading1 {
	font-size: 42px;
}


.teams-bl {
	max-width: 1040px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.team-bl {
		border: 1px solid #e8e8e8;

		.team-dt {

			border-top: none;
			padding: 20px 8px;
			text-align: center;
		}
	}
}


.contact-page {
	.control-label {
		text-align: left;
		padding-bottom: 5px;
	}

	.form-control {
		border: none;
		border-bottom: 2px solid #f2f2f2;
		text-transform: uppercase;
		margin-bottom: 30px;
		border-radius: 0;
		box-shadow: inherit;
		padding: 0;
	}

	label {
		color: var(--black);
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 0;
		padding-bottom: 10px;
	}

	.checkbox {
		label {
			text-transform: inherit;
			font-weight: normal;
		}

		a {
			color: var(--primary);
			font-size: 14px;
		}

		.privacy-link {}

	}

	.btn-go {
		box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
		font-size: 20px;
	}
}

.free-time-activities {
	.section-title {
		max-width: 100%;
		width: 100%;
		text-align: center;
	}

	.box {
		background-color: #fff;
		border: 1px solid #e1e1e1;
		padding: 5px;
		margin: 0 0 20px 0;

		.img-section {
			img {
				width: 100%;
			}
		}

		.text-section {
			padding: 20px 15px 10px 15px;

			h4 {
				margin: 0 0 5px 0;
				font-size: 20px;
				text-transform: uppercase;
				line-height: 1.4em;
			}

			h5 {
				margin: 0 0 20px 0;
				color: var(--primary);
				font-weight: 600;
			}

			h6 {
				margin: 0 0 10px 0;
				font-size: 15px;
				font-weight: 600;

				a {
					color: var(--primary);

					&:hover {
						color: var(--secondary);
					}
				}
			}
		}
	}
}

.faq-content {
	h4 {
		margin: 0 0 15px 0;
		font-size: 22px;

		a {
			color: var(--primary);
		}
	}

	ul {
		list-style: none;
		padding: 0;

		li {
			font-size: 16px;
			margin: 0 0 5px;
			position: relative;
			padding: 0 0 0 15px;

			&:before {
				font-family: "Font Awesome 5 Free";
				content: "\f054";
				display: block;
				font-weight: 600;
				font-size: 10px;
				position: absolute;
				left: 0;
				top: 5px;
			}

			a {
				color: #666;

				&:hover {
					color: var(--primary);
				}

			}
		}
	}

	.faq-horizontal {
		margin: 50px 0 0 0;

		h2 {
			margin: 0 0 20px 0;
		}

		.faq-strip {
			display: flex;
			flex-wrap: wrap;
			padding: 15px 0;
			margin: 0 0 10px 0;

			h5 {
				margin: 0 0 10px 0;
				font-weight: 600;
			}

			p {
				margin: 0 0 10px 0;

				a {
					color: var(--primary);

					&:hover {
						opacity: 0.7;
					}
				}
			}
		}

		.faq-strip:nth-child(even) {
			background-color: #eee;
		}
	}
}

.aside-widget {
	ul {
		padding: 0 0 0 0;
		margin-bottom: 0;
	}
}

.contact-content {
	iframe {
		@media (max-width: 991px) {
			height: 350px !important;
		}
	}
}

.ct-form-section {
	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;

		a {
			color: var(--primary);

			&:hover {
				color: var(--secondary);
			}
		}
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;

		a {
			background: var(--primary);
			color: #fff;
			border-radius: 3px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--secondary);
			}
		}
	}
}